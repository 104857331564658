<template>
  <v-container id="category" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showError">
        {{ showErrorData }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                :items="categories"
                label="Select Categories"
                item-text="name"
                item-value="_id"
                v-model="categoryId"
                :rules="selectRule"
                :disabled="onlyView"
              ></v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Name"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-checkbox
                v-model="isHidden"
                label="Tick to hide this subcategory.."
                label-color="red"
                color="#cf9602"
                hide-details
                :disabled="onlyView"
                class="hidden-policy-checkbox"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editSubCategory == true
                    ? "Update"
                    : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      isHidden: false,
      categories: [],
      showErrorData: null,
      showError: false,
      name: "",
      categoryId: "",
      inputRule: [(v) => !!v || "Field is required"],
      selectRule: [(v) => !!v || "Select any store"],
      onlyView: false,
      editSubCategory: null,
      loading: false,
    };
  },
  methods: {
    getCategoryList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "category/getall")
        .then((response) => {
          if (response.status == 200) {
            this.categories = response.data.categories;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubCategoryData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "sub-category/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.name = response.data.subCategories.name;
            this.categoryId = response.data.subCategories.category_name[0]._id;
            this.isHidden = response.data.subCategories.hidden;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          name: this.name,
          category_id: this.categoryId,
          hidden: this.isHidden,
        };
        if (this.onlyView) {
          this.$router.push({ name: "SubCategories" });
        } else if (this.editSubCategory == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "sub-category/update", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "SubCategories",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "sub-category/create", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "SubCategories",
                  params: { add: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        }
      }
    },
  },
  mounted() {
    this.getCategoryList();
    if (this.id != undefined) {
      this.getSubCategoryData(this.id);
      this.editSubCategory = true;
    } else {
      this.editSubCategory = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>

<style>
.hidden-policy-checkbox .v-label {
  color: red; /* Change the label color to red */
}
</style>
